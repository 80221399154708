import React from "react";
import "./styles.scss";

export default function RainbowStrip({variant = "basic", anim = false}) {
    const colors = {
        basic: ["red", "orange", "yellow", "green", "blue", "violet"],
        full: ["black", "brown", "transblue", "transpink", "red", "orange", "yellow", "green", "blue", "violet"],
    }
    return <div className="rainbow-strip">
        <div className={anim ? "anim" : ""}>
            {colors[variant].map(color => 
                <div key={color} className={color}/>
            )}
            {colors[variant].map(color => 
                <div key={color} className={color}/>
            )}
        </div>
    </div>;

}