import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { LocaleContext } from "../components/Layout"

const useTranslations = () => {
  const { locale } = React.useContext(LocaleContext)
  const {configJson} = useStaticQuery(query)
  return configJson.translations.reduce((result, translation) => ({
    ...result,
    [translation.id]: translation[locale]
  }), {})
}

export default useTranslations

const query = graphql`
  query useTranslations {
    configJson {
      translations {
        id
        pl
        en
        uk
        ru
      }
    }
  }
`
