import React from "react";
import "./styles.scss"
import useTranslations from "../../hooks/useTranslations";

export default function CookieBanner({setAreCookiesAllowed}) {
    const allowCookies = allowed => {
        const getExpires = days => new Date(Date.now() + (days*24*60*60*1000)).toUTCString()
        if (allowed) {
            document.cookie = "allow_cookies=1;expires=" + getExpires(9999) + ";path=/";
            setAreCookiesAllowed(true)
        } else {
            document.cookie = "allow_cookies=0;expires=" + getExpires(1) + ";path=/";
            setAreCookiesAllowed(false)
        }
    }
    const trans = useTranslations()
    return <section className={"cookie-banner"}>
        <p>{trans.cookiesNotice}</p>
        <div>
            <button className={"button accept"} onClick={() => allowCookies(true)}>{trans.cookiesAccept}</button>
            <button className={"clear-button decline"} onClick={() => allowCookies(false)}>{trans.cookiesDeny}</button>
        </div>
    </section>
}