import React, {useContext} from "react"
import "./styles.scss"
import love from "../../images/transheart.svg";
import {faFacebook, faInstagram} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "gatsby";
import RainbowStrip from "../RainbowStrip";
import i18n from "../../../config/i18n.json"
import useTranslations from "../../hooks/useTranslations";

export default function Footer({alts}) {
    const trans = useTranslations()
    return (
        <footer className={"site-footer"}>
            <RainbowStrip/>
            <div className="footer-contents">
                <div className={"icon-links"}>
                    <a href={"https://www.facebook.com/nowatecza"}>
                        <FontAwesomeIcon icon={faFacebook} size={"2x"}/>
                    </a>
                    <a href={"https://www.instagram.com/nowatecza"}>
                        <FontAwesomeIcon icon={faInstagram} size={"2x"}/>
                    </a>
                </div>
                <p className={"langs"}>
                    {typeof alts === "object" ? Object.entries(alts).map(([lang, path]) => {
                        return <Link key={lang} to={path}>{i18n.locales[lang].name}</Link>
                    }) : ""}
                </p>
                <p>© 2023 {trans.copyright} | <Link to={`/pl/polityka-prywatnosci/`}>{trans.privacyPolicy}</Link></p>
                <p className={"credit"}>Made with <img src={love} alt={"love"}/> by cddlee</p>
            </div>
        </footer>
    )
}