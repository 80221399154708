import {locales, pages} from "../../config/i18n.json"

function useRoutes(defaultPath) {
    const defaultLang = 'pl'
    const routesDict = pages[`Component${defaultPath}`]
    return Object.keys(locales).reduce((result, lang) => ({
        ...result,
        [lang]: typeof routesDict[lang] !== "undefined" ? 
            routesDict[lang] :
            routesDict[defaultLang]
    }), {})
}

export default useRoutes;